<template>
  <div>
    <c-tab
      ref="hazopActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :equipType.sync="tab.equipType"
          :popupParam.sync="popupParam"
          :facilityStatusTable="facilityStatusTable"
          :contentHeight="contentHeight"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'facility-status-table-equip',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    hcfFacilityTypeItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'device',
      tabItems: [
        {
          name: 'device', label: '장치ㆍ설비<br/>목록 및 명세', 
          equipType: { code: 'device', name: '장치ㆍ설비' },
          component: () => import(`${'./facilityStatusTableEquipInfo.vue'}`)
        },
        {
          name: 'power', label: '동력기계<br/>목록 및 명세', 
          equipType: { code: 'power', name: '동력기계' },
          component: () => import(`${'./facilityStatusTableEquipInfo.vue'}`)
        },
      ],
      editable: true,
    };
  },
  computed: {
    tabHeight() {
      return (this.contentHeight ? this.contentHeight - 80 : 0) + 'px'
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
